<template>
    <div class="w-screen h-screen">
        <Hexagon :items="show.items"  v-if="show.shape == 'hexagon'" />
        <Straight :items="show.items" v-else-if="show.shape == 'square'" />
        <div v-else class="text-white bg-black">Type of carousel not yet designed</div>
    </div>
</template>

<script>
import Hexagon  from './../Carousels/Hexagon'
import Straight  from './../Carousels/Hexagon'

export default {
    components: {
        Hexagon,
        Straight,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        layer: Number,
    },
    computed: {
        show(){
            console.log("Show Retrieved",this.$store.state.config.shows[this.id]);
            return this.$store.state.config.shows[this.id];
        },
    },
}
</script>