import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import Filesystem from './filesystem';
import createLaravelEchoPlugin from './echo';
import { difference, isEqual } from 'lodash';

Vue.use(Vuex);

const registeredVariables = ['code', 'token', 'display', 'config'];

export default new Vuex.Store({
    state: {
        code: '',
        token: '',
        display: -1,
        showLoaded: false,
        config: {},
        showStack: [],
        client: null,
        online: null,
        offlineIndicatorTimeout: null,
        offlineIndicatorOn: null,
        registrationInterval: null,
        error: null,
        filesystem: new Filesystem(1024*1024*1024*10), // 10GB for now
        files: [], // file handles to keep track of files
        downloading: false,
        hostname: process.env.VUE_APP_HOST,
        httpPort: process.env.VUE_APP_HOST_PORT,
        echoHost: process.env.VUE_APP_HOST,
        echoPort: process.env.VUE_APP_ECHO_PORT || 6001,
        echoSecret: process.env.VUE_APP_ECHO_SECRET,
        echoKey: process.env.VUE_APP_ECHO_KEY,
    },
    mutations: {
        initializeStore(state){
            registeredVariables.forEach(variable => {
                if(localStorage.getItem(variable) !== undefined) state[variable] = localStorage.getItem(variable);
            });
            if(typeof state.config == 'string') state.config = JSON.parse(state.config);
        },
        setCode(state, code){
            state.code = code;
            localStorage.setItem('code',code);
        },
        registerDisplay(state, payload) {
            state.error = null;
            state.display = payload.display;
            localStorage.setItem('display', payload.display);
            if(payload.token !== undefined) {
                state.token = payload.token;
                localStorage.setItem('token', payload.token);
            }
            if('echo' in payload){
                state.echoHost   = payload.echo.host;
                state.echoPort   = payload.echo.port;
                state.echoKey    = payload.echo.key;
                state.echoSecret = payload.echo.secret;
            }
        },
        loadShow(state){
            state.showLoaded = true;
        },
        setError(state, msg){
            state.error = msg;
        },
        connectEcho(){/*Echo*/},
        connectedEcho(state, echo){
            state.client = echo;
        },
        listenEcho(state, payload){
            if(state.client === null){
                console.error("Echo not setup");
                return false;
            }
            state.client.channel(payload.channel).listen(payload.event, payload.function);
            return true;
        },
        listenPrivateEcho(state, payload){
            if(state.client === null){
                console.error("Echo not setup");
                return false;
            }
            state.client.private(payload.channel).listen(payload.event, payload.function);
            return true;
        },
        setOnline(state,status){
            if(state.online !== status){ // don't do if null going to true
                state.offlineIndicatorOn = false;
                state.offlineIndicatorTimeout = setTimeout(()=>{
                    state.offlineIndicatorOn = true;
                    state.offlineIndicatorTimeout = setTimeout(()=>{
                        state.offlineIndicatorOn = false;
                        state.offlineIndicatorTimeout = setTimeout(()=>{
                            state.offlineIndicatorOn = null;
                        },700);
                    },5000);
                },700);
            }
            state.online = status;
        },
        setCache(state,payload = []){
            const message = {
                type: 'UPDATE_LIST',
                list: payload,
            };
            navigator.serviceWorker.controller.postMessage(message);
        }
    },
    actions: {
        async prepareShow({ state, commit, dispatch },newConfig = null){
            state.showLoaded = false;

            if(newConfig === null && state.config === null) return;

            // Check for difference
            if(!(newConfig === null || isEqual(newConfig,state.config))){
                // Cleanup
                if(state.config !== null){
                    await dispatch('cleanFiles',newConfig.files);
                }
                // Add to state
                state.config = newConfig;
                // Add to local storage
                localStorage.setItem('config',JSON.stringify(newConfig));
            }

            commit('setCache',state.config.cache);

            if(typeof state.config.files === 'object' && Object.keys(state.config.files).length > 0) {
                await dispatch('loadFiles',true);
                await dispatch('dlFiles');
            } 

            commit('loadShow');
        },
        loadFiles({state},rejectable = false){
            if(state.config.files && Object.keys(state.config.files).length > 0){
                const filePromises = [];
                Object.keys(state.config.files).forEach(filename=>{
                    filePromises.push(state.filesystem.getFile(filename).then(file=>{
                        state.files[filename] = file;
                    }));
                });
                if(rejectable) return Promise.allSettled(filePromises);
                return Promise.all(filePromises);
            }
            return Promise.resolve();
        },
        cleanFiles({state}, newFiles){
            if(typeof state.config.files !== 'object') return;
            const filesToRemove = difference(Object.keys(state.config.files),Object.keys(newFiles||{}));
            const filePromises = [];
            filesToRemove.forEach(file => {
                filePromises.push(state.filesystem.rmFile(file));
            });
            return Promise.all(filePromises);
        },
        async dlFiles({state, getters}){
            // loadFiles should have been called by now.
            state.downloading = true;
            const downloads = [];
            for(const [filename, attrs] of Object.entries(state.config.files)){
                if(filename in state.files) {
                    // get modified date of file
                    if((state.files[filename].lastModified / 100) > attrs.modified && state.files[filename].size == attrs.size) {
                        console.log(filename,'is up to date.');
                        continue;
                    }
                }
                console.log("Downloading",filename);
                downloads.push(axios.post(getters.url + '/client/files/' + filename, {
                        // Auth
                        code: state.code,
                        token: state.token,
                    },{
                        responseType: 'blob',
                    })
                    .then(response => {
                        console.log("Done downloading",filename);
                        return state.filesystem.write(filename, response.data)
                    })
                    .then(handle => ({filename:filename,file:handle}))
                );
            }
            if(downloads.length > 0){
                await Promise.all(downloads).then((handles)=>{
                    state.downloading = false;
                    console.log("downloading done",handles);
                    handles.forEach(handle=>{
                        state.files[handle.filename] = handle.file;
                    });
                });
            }
            state.downloading = false;
        },
        setStatus({state, getters},status){
            const data = {
                code: state.code,
                token: state.token,
                status,
            };
            return axios.post(getters.url + '/client/status',data);
        },
        registerDisplay({state, commit, dispatch, getters}){
            if(state.hostname == process.env.VUE_APP_HOST && location.protocol !== 'https:' && location.hostname != 'localhost'){
                location.replace(`https:${location.href.substring(location.protocol.length)}`);
            }
            // Once registration is initiated, we continue until we register fully
            if(state.registrationInterval === null){
                state.registrationInterval = setInterval(()=>dispatch('registerDisplay'),10000);
            }
            // If offline
            if(!navigator.onLine) {
                // if offline and we have a configuration, load it
                if(Object.keys(state.config).length > 0 && state.online === null) {
                    commit('setOnline',false);
                    if(!(state.token === null || state.token === '')) {
                        dispatch('prepareShow');
                    }
                }
                return;
            }
            const data = {};
            if(!(state.code === null || state.code === '')) {
                data.code = state.code;
                if(!(state.token === null || state.token === '')) {
                    data.token = state.token;
                }
            }
            return axios.post(getters.url + '/client/register',data).then((response)=>{
                commit('setOnline',true);
                if(response.data){
                    if(state.code === null || state.code === ''){
                        // Should respond with code since nothing was given
                        if('code' in response.data) commit('setCode', response.data.code);
                        else commit('setError',"No code given.");
                    }
                    else if(response.data.status === 'registered') {
                        // Display is registered, so we just pass on the data
                        clearInterval(state.registrationInterval);
                        commit('registerDisplay', response.data);
                        if(state.display > 0 && state.client === null) {
                            // Start listening for changes
                            commit('connectEcho');
                        }
                        dispatch('getConfig');
                    } else if (response.data.status == 'conflict') {
                        commit('setError',"Registration Conflict.");
                    } else if (response.data.status == 'deleted') {
                        commit('registerDisplay',{
                            token: '',
                            display: -1,
                        });
                    } else if (response.data.status == 'invalid') {
                        // means code is invalid on the server. Need to reset code and token.
                        commit('setCode','');
                        commit('registerDisplay',{
                            token: '',
                            display: -1,
                        });
                    }
                } else {
                    console.error("No info was given with registration");
                }
            },(error)=>{
                console.error("Failed registration push",error);
                if('response' in error && error.response !== undefined){
                    if(state.code === null || state.code === '') commit('setError',"Failed to get code. Code " + error.response.status + ". ");
                    else commit('setError',"Failed to get registration. Code " + error.response.status + ". ");
                    clearInterval(state.registrationInterval);
                } else {
                    // Was not able to connect. Don't try to connect, just run prepare show.
                    // However, we will only run prepare once (online === null means first-time)
                    if(state.code === null || state.code === '') this.$store.commit('setError',"Failed to get code. " + error.message);
                    else if(state.config !== null && Object.keys(state.config).length > 0 && state.online === null) {
                        commit('setOnline',false);
                        if(!(state.token === null || state.token === '')) {
                            dispatch('prepareShow');
                        }
                    }
                }
            });
        },
        getConfig({state, commit, dispatch, getters}){
            if(state.token === null || state.token === '') return;
            const data = {
                token: state.token,
                code: state.code,
            };
            return axios.post(getters.url + '/client/config',data).then(response => {
                commit('setOnline',true);
                dispatch('prepareShow',response.data);
            });
        },
        setHostname({state, commit}, args){
            let hostname = args.hostname;
            if(state.hostname != hostname) {
                const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
                const hostRegex = /^([a-z0-9][a-z0-9-]*[a-z0-9]\.)*([a-z0-9][a-z0-9-]*[a-z0-9])$/i;
                if(ipRegex.test(hostname)? hostRegex.test(hostname) : !hostRegex.test(hostname)){
                    commit('setError',"Hostname must be a valid ip or FQDN. Got " + hostname);
                    return false;
                }
                
                if(hostname === null) hostname = process.env.VUE_APP_HOST;
                state.hostname = hostname;
                if('port' in args && /[0-9]+/.test(args.port)){
                    state.httpPort = args.port;
                }
                if('wsport' in args && /[0-9]+/.test(args.wsport)){
                    state.echoPort = args.wsport;
                }
                return true;
            }
        },
    },
    getters: {
        status(state){
            if(state.error) return "<span class=\"text-red-600\">Error:</span> " + state.error;
            if(state.code === '' || state.code === null) return "No Code";
            if(state.token === '' || state.token === null || state.display < 0) return "Display not registered";
            if(state.config === undefined || state.config === null || state.config.name === undefined) return "No show configured for this display";
            if(state.downloading) return "Downloading files for show " + state.config.name;
            if(state.showLoaded == false) return "Loading show " + state.config.name;
            return "Ready";
        },
        url(state){
            let hostname = "";
            if(state.hostname == 'localhost'){
                hostname += "http://";
            } else {
                hostname += location.protocol + "//";
            }
            hostname += state.hostname;
            if(state.httpPort) hostname += ':' + state.httpPort;
            return hostname;
        }
    },
    plugins: [
        createLaravelEchoPlugin(),
    ],
})
