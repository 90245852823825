import Echo from 'laravel-echo';
// eslint-disable-next-line no-unused-vars
import Pusher from "pusher-js"

export default function createLaravelEchoPlugin(){
    return store => {
        store.subscribe((mutation, state) => {
            if (state.online === true && mutation.type === 'connectEcho') {
                if(state.code === '' || state.code === null || state.token === '' || state.token === null) {
                    console.error("Not setup. Cannot connect to Vapor Echo.");
                    return;
                }
                store.commit('connectedEcho',new Echo({
                    broadcaster: 'pusher',
                    key: state.echoKey,
                    secret: state.echoSecret,
                    wsHost: state.echoHost, 
                    wsPort: state.echoPort,
                    httpHost: state.hostname,
                    httpPort: state.httpPort,
                    forceTLS: location.protocol === 'https:' && state.hostname != 'localhost',
                    disableStats: true,
                    authEndpoint: store.getters.url + '/client/broadcasting/auth',
                    auth: {
                        params: {
                            code: state.code,
                            token: state.token,
                        }
                    }
                }));
                store.commit('listenPrivateEcho', {
                    channel: 'client.display.'+state.display,
                    event: 'ClientDisplayUpdated',
                    function: (e) =>{
                        console.log("Show Updated",e);
                        store.dispatch('getConfig');
                    },
                });
            }
        });
    }
}
