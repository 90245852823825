<!-- Single is a wrapper to resolve the show's format -->
<template>
    <div class="absolute top-0 right-0 w-screen h-screen overflow-hidden">
        <div class="flex justify-center w-screen h-screen overflow-hidden">
            <item-player :config="show" />
        </div>
    </div>
</template>
<script>
import ItemPlayer from './../ItemPlayer'

export default {
    name: "Single",
    components: {
        ItemPlayer,
    },
    props: {
        id: Number,
        layer: Number,
    },
    computed: {
        show(){
            return this.$store.state.config.shows[this.id];
        },
    },
}
</script>