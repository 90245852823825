
export default class Filesystem {
    constructor(requestedBytes) {
        navigator.webkitPersistentStorage.requestQuota (
            requestedBytes, (grantedBytes) => {
                window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
                window.requestFileSystem(1, grantedBytes, (filesystem) => {
                    this.filesystem = filesystem;
                }, error => {
                    console.log("File system error",error);
                });

            }, function(e) { console.log('Error', e); }
        );
        // TODO: IndexedDB for file handles. Would make the functions below easier.
    }

    lsDir(path){
        return new Promise((resolve, reject)=>{
            this.getDir(path).then(dir=>{
                dir.createReader().readEntries(resolve,reject);
            });
        });
    }

    mkdir(path){
        return new Promise((resolve, reject)=>{
            this.getDir(path,true).then(dir=>{
                dir.createReader().readEntries(resolve,reject);
            });
        });

    }

    async write(path,blob){
        path = path.split('/');
        const name = path.pop();
        const dir = await this.getDirEntry(path, true);
        const file = await this.getFileEntry(dir, name, true);
        return new Promise((resolve, reject) => {
            file.createWriter(writer => {
                writer.onwriteend = ()=>{
                    file.file(resolve,reject);
                };
                writer.onerror = reject;
                writer.write(blob);
            });
        });
    }

    /**
     * Gets a file handle for the given path
     * @param {String} path 
     * @returns {Promise} Resolves to a File Object
     */
    async getFile(path){
        path = path.split('/');
        const name = path.pop();
        const dir = await this.getDirEntry(path);
        const file = await this.getFileEntry(dir,name);
        return new Promise((resolve, reject)=>{
            file.file(resolve,reject);
        });
    }

    /**
     * 
     * @param {String} path 
     */
    async rmFile(path){
        path = path.split('/');
        const name = path.pop();
        const dir = await this.getDirEntry(path);
        const file = await this.getFileEntry(dir,name);
        return new Promise((resolve, reject)=>{
            file.remove(resolve, reject);
        });
    }

    async rmDir(path){
        path = path.split('/');
        const dir = await this.getDirEntry(path);
        return new Promise((resolve, reject)=>{
            dir.remove(resolve, reject);
        });
    }

    /**
     * Gets a FileSystem DIrectory Entry recursively
     * @param {String,Array} paths 
     * @param {Boolean} create 
     * @returns {Promise} Resolves to a FileSystemDirectoryEntry
     */
    async getDirEntry(paths, create = false){
        let cd = this.filesystem.root; // an object?
        let p = Promise.resolve();
        if(typeof paths !== 'object') {
            paths = paths.split('/');
        }
        paths.forEach(path  => {
            p = p.then(() => {
                return new Promise((resolve,reject)=>{
                    cd.getDirectory(path,{create},resolve,reject);
                });
            });
        });
        return p;
    }

    async getFileEntry(directory, filename, create = false){
        return new Promise((resolve, reject)=>{
            directory.getFile(filename,{create},resolve,reject);
        });
    }
}