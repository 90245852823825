<template>
    <transition name="fade" v-bind="$attrs" 
        enter-active-class="transition duration-500 ease-out"
        leave-active-class="transition duration-500 ease-in"
        enter-class="opacity-0 scale-70"
        enter-to-class="opacity-100 scale-100"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-70"
    >
        <slot></slot>
    </transition>  
</template>
<script>
export default {
    inheritAttrs: false
};

//v-on="$listeners"
</script>
