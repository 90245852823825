<template>
    <div class="inline-block relative p-0 my-0 mx-px">
        <span class="back absolute top-0 left-0 font-digital xl:text-10xl lg:text-9xl md:text-8xl sm:text-7xl text-5xl" :class="classes" v-for="(char, index) in back.split('')" :key="index">
            {{ char }}
        </span>
        <span class="front font-digital xl:text-10xl lg:text-9xl md:text-8xl sm:text-7xl text-5xl transition-opacity duration-200 ease-linear" :class="classes" :style="{opacity:visible}" v-html="front">
            
        </span>
    </div>
</template>

<script>
export default {
    props: {
        color: String,
        back: String,
        front: [String, Number],
        regular: Boolean,
    },
    data(){
        return {
            visible: 1,
            timeout: null,
        }
    },
    mounted() {
        let interval = Math.random()*50000 + 3000;
        if(this.regular === true) interval = 500;
        this.timeout = setTimeout(this.disappear,interval);
    },
    methods: {
        disappear(){
            this.visible = 0;
            let interval = Math.random()*500+100;
            if(this.regular === true) interval = 500;  
            this.timeout = setTimeout(this.reappear,interval);
        },
        reappear(){
            this.visible = 1;
            let interval = Math.random()*50000;
            if(this.regular === true) interval = 500;
            this.timeout = setTimeout(this.disappear,interval);
        },
    },
    computed: {
        classes(){
            const classes = {};
            classes[this.color] = true;
            if(this.color == 'rainbow') classes['blue'] = true;
            return classes;
        },
    },
    watch: {
        regular(){
            let interval = Math.random()*50000;
            if(this.regular === true) interval = 500;
            this.visible = 1;
            this.timeout = setTimeout(this.disappear,interval);
        }
    }
}
</script>

<style scoped>
@font-face {
    font-family: 'digital';
    src: url('../assets/digitaldream.ttf') format('truetype');
}

@font-face {
    font-family: 'digital';
    src: url('../assets/digitaldreamfat.ttf') format('truetype');
    font-weight: bold;
}

.back {

    z-index: -1;
}

.font-digital {
    font-family: 'digital';
    font-style: normal;
}

.green.front {
    color: #55FF55;
    text-shadow: 0 0 10px #55FF55,0 0 20px #22FF22,0 0 30px #11FF11, 0 0 40px #00FF00, 0 0 70px #00CC00;
}

.red.front {
    color: #FF5555;
    text-shadow: 0 0 10px #FF5555,0 0 20px #FF2222,0 0 30px #FF1111, 0 0 40px #FF0000, 0 0 70px #CC0000;
}

.blue.front {
    color: #5555FF;
    text-shadow: 0 0 10px #5555FF,0 0 20px #2222FF,0 0 30px #1111FF, 0 0 40px #0000FF, 0 0 70px #0000CC;
}

.clock:not(.blue):not(.red):not(.green):not(.black).front {
    color: #FFF;
    text-shadow: 0 0 10px #FFF,0 0 20px #CCC,0 0 30px #AAA, 0 0 40px #888, 0 0 70px #666;
}

.clock:not(.blue):not(.red):not(.green):not(.black).back {
    color: #303030;
}

.green.back {
    color: #002000;
}

.red.back {
    color: #200000;
}

.blue.back {
    color: #000020;
}

.invert {
    filter: invert(100%);
}

.rainbow {
  animation: hue 300s infinite;
  -webkit-animation: hue 300s infinite;
}

@-webkit-keyframes hue {
  0%, 100%  { -webkit-filter: hue-rotate(0deg); }
  50% { -webkit-filter: hue-rotate(180deg); }
}

@keyframes hue {
  0%, 100%  { -webkit-filter: hue-rotate(0deg); }
  50% { -webkit-filter: hue-rotate(180deg); }
}
</style>
