<template>
<div @click="resetTimeout" class="absolute top-0 right-0 w-screen h-screen overflow-hidden">
    <div class="w-screen h-screen text-white grid auto-rows-auto justify-center content-center overflow-x-hidden" :class="columnClasses" v-show="selectedItem === null">
        <div v-for="(item,i) in show.items" :key="i" class="border-white border" :class="itemClasses" @click="selectedItem = item">
            Click Me to select: {{ item.path }}
        </div>
    </div>
    <fade-transition v-for="(item,i) in show.items" :key="i">
        <div class="absolute top-0 left-0 w-screen h-screen text-white" v-show="selectedItem == item">
            <item-player :config="item" />
            <div class="absolute w-16 h-16 p-2 text-white z-30 home-button flex content-center justify-center rounded-lg" @click="selectedItem = null">
                <feather type="home" class="h-full items-center text-white" />
            </div>
        </div>
    </fade-transition>
    <div class="absolute z-50 top-0 right-0 text-white m-2" v-if="selectedItem !== null">{{ selectedItem.path }}</div>
</div>
</template>

<style lang="scss" scoped>
    .h-half-screen {
        height: 50vh;
    }
    .h-quarter-screen {
        height: 25vh;
    }
    .h-eighth-screen {
        height: 12.5vh;
    }
    .home-button {
        bottom: 5vh;
        right: 5vh;
        background-color: rgba(25,25,25,0.5)
    }
</style>

<script>
import ItemPlayer from './../ItemPlayer'
import FadeTransition from '../FadeTransition'

export default {
    components: {
        ItemPlayer,
        FadeTransition,
    },
    props: {
        id: Number,
        layer: Number,
    },
    data() {
        return {
            selectedItem: null,
            counter: -1,
            interval: setInterval(this.runInterval,1000),
        }
    },
    computed: {
        columnClasses(){
            const classes = [];
            if(this.show.items.length < 5) classes.push('grid-cols-2');
            else classes.push('grid-cols-4');
            return classes;
        },
        itemClasses(){
            const classes = [];
            if(this.show.items.length < 5) classes.push('h-half-screen');
            else classes.push('h-quarter-screen');
            return classes;
        },
        show(){
            return this.$store.state.config.shows[this.id];
        },
    },
    methods: {
        resetTimeout(){
            this.counter = 300;
        },
        runInterval(){
            if(this.counter < 0) return;
            if(this.counter == 0){
                this.selectedItem = null;
                this.counter = -1;
            }
            this.counter--;
        },
    }
}
</script>
