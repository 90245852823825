<template>
    <div>
        <fade-transition>
            <standby-clock v-if="$store.getters.status != 'Ready'" key="status"></standby-clock>
            <component :is="$store.state.config.shows[$store.state.config.main].type" :id="$store.state.config.main" v-else key="show"></component>
        </fade-transition>
        <div class="absolute z-50 top-0 left-0  bg-black rounded py-1 px-2 text-xl font-semibold m-5 transition-opacity duration-700 ease-in-out" :class="{'hidden opacity-0':$store.state.offlineIndicatorOn === null,'opacity-0':$store.state.offlineIndicatorOn === false, 'opacity-100':$store.state.offlineIndicatorOn === true }">
            <span v-if="$store.state.online === true" class="text-green-800">Online</span>
            <span v-else-if="$store.state.online === false" class="text-red-800">Offline</span>
            <span v-else-if="$store.state.online === null" class="text-orange-800">Unknown Online State</span>
        </div>
        <fade-transition>
            <div class="absolute z-50 top-0 left-0  bg-black rounded py-1 px-2 text-xl font-bold m-5 text-orange-500 bg-gray-800" v-if="updateAvailable" @click="refreshApp">Update Available</div>
        </fade-transition>
    </div>
</template>

<style>
body {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
</style>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import Clock from './components/shows/Clock';
import Blank from './components/shows/Blank';
import StandbyClock from './components/StandbyClock';
import Single from './components/shows/Single';
import Carousel from './components/shows/Carousel';
import Grid from './components/shows/Grid';
import FadeTransition from './components/FadeTransition';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import Vue from "vue";

Sentry.init({
  Vue,
  dsn: "https://d92661014e184ef2aebeeef56afe7a74@sentry.blackhawkelectronics.com/2",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

export default {
    name: 'App',
    components: {
        StandbyClock,
        Blank,
        Clock,
        Single,
        Carousel,
        Grid,
        FadeTransition,
    },
    data(){
        return {
            registration: null,
            updateAvailable: false,
            refreshing: false,
        }
    },
    beforeCreate(){
        this.$store.commit('initializeStore');
    },
    created(){
        console.log("Running commit " + (process.env.VUE_APP_COMMIT || 'dev'));
        document.addEventListener('swUpdated', this.updateReady, { once: true });
        if(navigator.serviceWorker) navigator.serviceWorker.addEventListener('controllerchange', ()=>{
            if(this.refreshing) return;
            this.refreshing = true;
            window.location.reload();
        });
        const uri = window.location.href.split('?');
        const args = {};
        if(uri.length == 2){
            const varsTemp = uri[1].split('&');
            varsTemp.forEach(function(v){
                const tmp = v.split('=');
                if(tmp.length == 2) args[tmp[0]] = tmp[1];
                else args[tmp[0]] = null;
            });
        }
        if('hostname' in args){
            if(this.$store.dispatch('setHostname',args)){
                this.$store.dispatch("registerDisplay");
            }
        } else {
            this.$store.dispatch("registerDisplay");
        }
        this.setupSW();
    },
    methods: {
        async setupSW(){
            this.registration = await navigator.serviceWorker.getRegistration();
            if(this.registration) {
                this.registration.addEventListener('updatefound',()=> { // Method 2 for service worker update since swUpdated does not always work
                    this.updateExists = true;
                }, { once: true });
            }
        },
        updateReady(){
            this.registration = event.detail;
            this.updateAvailable = true;
        },
        refreshApp(){
            this.updateExists = false;
            if(!this.registration || !this.registration.waiting) return;
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        },
    },
}
</script>

<style lang="scss">
body {
    background-color: black;
    margin: 0;
}
</style>
