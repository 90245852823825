<template>
<div class="absolute top-0 right-0 w-screen h-screen overflow-hidden" @click="unselect">
    <info />
    <div class='flex items-center flex-no-wrap w-screen h-screen cursor-none m-0 overflow-hidden'>
        <div class="mx-auto whitespace-no-wrap">
            <clock-element :color="color" back="1" :front="(hour > 9)? '1':'&nbsp;'" />
            <clock-element :color="color" back="8/1" :front="hour % 10" />
            <clock-element :color="color" back=":" front=":" :regular="color != 'green'"/>
            <clock-element :color="color" back="81" :front="Math.floor(minute/10)" />
            <clock-element :color="color" back="8/1" :front="minute % 10" />
            <div class="inline-block xl:w-32 lg:w-24 md:w-20 sm:w-12 w-8"> </div>
            <clock-element :color="color" back="AP" :front="ampm" />
            <clock-element :color="color" back="M" front="M" />
        </div>
    </div>
    <div class='bottom-info select-text lg:text-lg md:text-base text-sm' @click.stop v-html="$store.getters.status"></div>
    <div class="bottom-reg select-all lg:text-lg md:text-base text-sm" @click.stop.prevent="copyCode" ref="code">
        {{ $store.state.code }}
    </div>
</div>
</template>

<script>
import ClockElement from './ClockElement';
import Info from './Info';

export default {
    components: {
        ClockElement,
        Info,
    },
    data(){
        const date = new Date();
        let hour = date.getHours();
        let ampm = 'A';
        if(hour > 11) ampm = 'P';
        if(hour == 0) hour = 12;
        if(hour > 12) hour -= 12;
        return {
            hour,
            ampm,
            minute: date.getMinutes(),
        }
    },
    created(){
        setInterval(() => {
            const date = new Date();
            let hour = date.getHours();
            
            if(hour > 11) this.ampm = 'P';
            else this.ampm = 'A';

            if(hour == 0) hour = 12;
            if(hour > 12) hour -= 12;
            
            this.hour = hour;
            this.minute = date.getMinutes();
        }, 1000);
    },
    computed: {
        color(){
            if(this.$store.state.error) return 'rainbow';
            if(this.$store.state.token == null || this.$store.state.token == '' || this.$store.state.display < 0) return 'red';
            return "green";
        }
    },
    methods: {
        copyCode(){
            let range;
            if(window.getSelection){
                range = document.createRange();
                range.selectNode(this.$refs.code);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
                document.execCommand('copy');
            } else if(document.selection) {
                range = document.body.createTextRange();
                range.moveToElementText(this.$refs.code);
                range.select();
                document.execCommand('copy');
            }
        },
        unselect(){
            if(window.getSelection){
                window.getSelection().removeAllRanges();
            }
        }
    },
}
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

.select-all {
  -webkit-touch-callout: all;
    -webkit-user-select: all;
     -khtml-user-select: all;
       -moz-user-select: all;
        -ms-user-select: all;
            user-select: all;
}

.select-text {
  -webkit-touch-callout: text;
    -webkit-user-select: text;
     -khtml-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
}

.clock-elements {
    position: absolute;
    text-align: center;
    width: 100vw;
    word-wrap: none;
}

.bottom-info {
    position:absolute; 
    bottom:2em;
    left: 2em;
    color: #c3cbcf;
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
}

.bottom-reg {
    position:absolute; 
    bottom:2em;
    right: 2em;
    color: #3b4761;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 110;
}
</style>
