<!-- This is an almost exact copy of the Server's ItemPlayer. Convert internal URL's to blobs -->
<template>
    <div v-if="config.format.startsWith('video/') || config.format == 'stream'" class="relative w-full h-full">
        <video class="w-full"
            :controls="config.interaction == 'controls'" :loop="config.interaction == 'autoplay'"
            ref="video" v-touch:tap="videoTap"
            @click="videoTap" @canplay="canplay" @ended="updatePaused" @pause="updatePaused" @play="updatePaused">
            <source :src="objectUrl(config.path)" :type="config.format" />
        </video>
        <fade-transition v-if="config.interaction == 'tap'">
            <img v-show="!playing" :src="objectUrl(config.tapImage)"
                class="absolute top-0 left-0 w-full min-h-full overflow-hidden" 
                @click="$refs.video.play()"/>
        </fade-transition>
    </div>
    <div v-else-if="config.format.startsWith('image/')" class="flex w-full h-full text-center items-center justify-center" :style="{'background-color':config.backgroundColor}">
        <img class="max-w-full max-h-full" :src="objectUrl(config.path)" /><!-- Change to imageviewer-->
    </div>
    <embed v-else-if="config.format == 'application/pdf'" class="w-full h-full" :src="objectUrl(config.path) + '#toolbar=0&navpanes=0'" :type="config.format" />
    <iframe v-else-if="config.format == 'url'" class="w-full h-full" :src="config.path" /><!-- need auto reset -->
    <div v-else-if="config.format == ''" class="text-red-500 text-center mx-auto">No Content Selected</div>
    <div v-else class="text-red-500 text-center mx-auto font-semibold">Content not Supported</div>
</template>

<script>
import FadeTransition from './FadeTransition'

export default {
    components: {
        FadeTransition,
    },
    props: {
        config: Object,
        selected: Boolean,
    },
    data(){
        return {
            playing: false,
        }
    },
    mounted() {
        //
    },
    methods: {
        videoTap(){
            if(this.config.interaction == 'autoplay') this.$refs.video.play();
            if(this.playing) this.$refs.video.pause();
            else this.$refs.video.play();
        },
        canplay(){
            console.log("video can play");
            if(this.config.interaction == 'autoplay' && this.selected !== false) this.$refs.video.play();
        },
        updatePaused(event) {
            this.playing = !event.target.paused;

            // If tap show, reset to beginning after we hit the end
            if(this.config.interaction == 'tap' && this.playing === false) {
                this.$refs.video.currentTime = 0;
            }
        },
        objectUrl(path){
            console.log("Getting path for ", path);
            try{
                return URL.createObjectURL(this.$store.state.files[path]);
            } catch (error) {
                console.error("Failed to get cached file.",error);
                this.$store.commit('setError','Failed to get cached file.');
            }
        },
    },
    watch: {
        selected(newVal){
            if(newVal === true){
                if(this.config.format.startsWith('video/') && this.config.interaction == 'autoplay') this.$refs.video.play();
            } else {
                if(this.config.format.startsWith('video/')){
                    this.$refs.video.pause();
                    this.$refs.video.currentTime = 0;
                }
            }
        },
    },
}
</script>