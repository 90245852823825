<template>
    <div @blur="this.open = false" @click="clicked">
        <fade-transition>
            <div class="absolute top-0 right-0 m-10 rounded rounded-tr-none px-2 py-1 border border-yellow-900 bg-gray-800 bg-opacity-75 text-white whitespace-no-wrap" v-show="open">
                <div class="block font-bold text-lg border-b border-white">About Vapor Client</div>
                <div class="block"><span class="font-semibold mr-1">Controller:</span> {{ $store.getters.url }}</div>
                <div class="block">
                    <span class="font-semibold mr-1">Connected:</span>
                    <span v-if="$store.state.online === true" class="text-green-700">Online</span>
                    <span v-else-if="$store.state.online === false" class="text-red-700">Offline</span>
                    <span v-else class="text-orange-700">Unknown</span>
                </div>
                <div class="block">
                    <span class="font-semibold mr-1">Live Link:</span>
                    <span :class="{'text-green-700':connectorState == 'Connected', 'text-red-700':connectorState == 'Disconnected'}">{{ connectorState }}</span>
                </div>
                <div class="block mt-1 pt-1 border-t border-white">
                    Created by Benjamin Plain<br>
                    Visit ChurchTech.Help for more info.
                </div>
                <div class="block mt-1 text-2xs whitespace-normal max-w-xs text-justify" v-if="$store.state.hostname == 'vapor.churchtech.help'">
                        <span class="font-semibold">Disclaimer:</span>
                        Show content is the responsibility of the show owner
                        and is not owned by CTH
                        and may not express the views of CTH.
                        CTH reserves the right to remove any content contrary to the usage and privacy agreements.
                    </div>
            </div>
        </fade-transition>
        <div class="absolute top-0 right-0 m-5 h-10 w-10 bg-teal-700 rounded-full shadow-xl transition duration-500 ease-in-out" :class="{'opacity-25':!open}">
            <feather type="help-circle" class="h-10 text-yellow-600" />
        </div>
    </div>
</template>

<script>
import FadeTransition from './FadeTransition';

export default {
    components: {
        FadeTransition,
    },
    data(){
        return {
            open: false,
        }
    },
    computed: {
        connectorState(){
            if(this.$store.state.client
            && this.$store.state.client.connector
            && this.$store.state.client.connector.pusher
            && this.$store.state.client.connector.pusher.connection 
            && this.$store.state.client.connector.pusher.connection.state) {
                let state = this.$store.state.client.connector.pusher.connection.state;
                state = state.charAt(0).toUpperCase() + state.slice(1);
                return state;
            } else return "Offline";
        }
    },
    methods: {
        clicked(){
            this.open = !this.open;
        }
    }
}
</script>