import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import store from './store';
import VueFeather from 'vue-feather';
import Vue2TouchEvents from 'vue2-touch-events'

import './assets/styles/index.css';

// Fontawesome

import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faUserSecret);

Vue.component('fa-icon',FontAwesomeIcon);
Vue.component('feather', VueFeather);
Vue.use(Vue2TouchEvents);

Vue.config.productionTip = false;

new Vue({
    store,
    render: h => h(App),
}).$mount('#app');
