<template>
    <div class="w-full h-full flex content-center align-middle overflow-hidden bg-gray-900"  v-touch:swipe.left="next" v-touch:swipe.right="prev" @drag.prevent>
        <div class="relative scene m-auto">
            <div class="w-full h-full absolute carousel transition duration-1000" ref="carousel" :style="{transform: 'translateZ(-'+radius+'px) rotateY('+(delta*selected*-1)+'deg)'}">
                <div v-for="(item,i) in items" :key="'x'+i" class="cell absolute border-black rounded border-2 transition duration-1000 bg-gray-800" :style="{transform:'rotateY('+(delta*i)+'deg) translateZ('+(radius-1)+'px)'}">
                </div>
                <div v-for="(item,i) in items" :key="i" class="cell absolute border-black rounded border-2 transition duration-1000" :style="{transform:'rotateY('+(delta*i)+'deg) translateZ('+radius+'px)'}">
                    <item-player class="cell-front" :config="item" :selected="selectedId == i" /><!-- todo: stop any playback or reset -->
                </div>
            </div>
        </div>
        <div class="absolute w-full top-0 left-0 text-white text-xl text-center pt-3 overflow-hidden">
            {{items[selectedId].title || "No Title"}}
        </div>
        <div class="absolute w-full bottom-0 left-0 text-white text-center pb-3 overflow-hidden">
            Swipe left/right <span v-if="items[selectedId].format == 'application/pdf'">outside of the document</span> to see more.
        </div>
        <div class="absolute h-full w-8 top-0 right-0 hover:bg-gray-900 overflow-hidden transition-colors duration-300 ease-in-out" @click="next" @v-touch:tap="next"></div>
        <div class="absolute h-full w-8 top-0 left-0 hover:bg-gray-900 overflow-hidden transition-colors duration-300 ease-in-out" @click="prev" @v-touch:tap="prev"></div>
    </div>
</template>

<style scoped>
.scene{
    perspective: 1000vw;
    height: 85vh;
    width: 85vw;
}
.carousel {
    transform-style: preserve-3d;
    
}
.cell {
    left: 0;
    top: 0;
    height: 85vh;
    width: 85vw;
}
</style>
    
<script>
import ItemPlayer from './../ItemPlayer'

export default {
    components: {
        ItemPlayer,
    },
    props: {
        'items': {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selected: 0,
            keyListener: null,
            resizeLister: null,
            resizes: 0,
            isMounted: false,
        }
    },
    mounted() {
        this.keyListener = document.addEventListener('onkeydown',(e)=>{
            if(e.keyCode == 39) this.next();
            if(e.keyCode == 37) this.prev();
        });
        window.addEventListener('resize',()=>this.resizes++);
        this.isMounted = true;
    },
    destroy() {
        document.removeEventListener(this.keyListener);
        this.isMounted = false;
    },
    computed: {
        delta(){
            return 360 / this.items.length;
        },
        width(){
            this.resizes;
            if(!this.isMounted || this.$refs.carousel === undefined) return 2 * screen.width;
            return this.$refs.carousel.clientWidth;
        },
        radius(){
            return Math.round((this.width/2) / Math.tan(Math.PI / this.items.length)) + 70;
        },
        selectedId(){
            return ((this.selected%this.items.length)+this.items.length)%this.items.length;
        },
    },
    methods: {
        next(){
            this.selected++;
            //if(this.selected > this.items.length - 1) this.selected = 0;
        },
        prev(){
            this.selected--;
            //if(this.selected < 0) this.selected = this.items.length - 1;
        }
    },
}
</script>